import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeNigeria from "../components/body/pages/en-ng"

const IndexPage = () => {
    
  return (
  <Layout>
    <SEO
      canonical={"https://kuda.com/"}
      title="Kuda | The Money App for Africans | Open an Account in Minutes"
      description="Kuda, the money app for Africans licensed by the CBN. Zero maintenance fees, free transfers, automatic savings & investments. Join Kuda today!"
      />

    <HomeNigeria />
  </Layout>
)}

export default IndexPage
